export default (function () {
    /*
     * Generated by PEG.js 0.8.0.
     *
     * http://pegjs.majda.cz/
     */
    function peg$subclass(child, parent) {
        function ctor() {
            this.constructor = child;
        }
        ctor.prototype = parent.prototype;
        child.prototype = new ctor();
    }
    function SyntaxError(message, expected, found, offset, line, column) {
        this.message = message;
        this.expected = expected;
        this.found = found;
        this.offset = offset;
        this.line = line;
        this.column = column;
        this.name = 'SyntaxError';
    }
    peg$subclass(SyntaxError, Error);
    function parse(input) {
        var options = arguments.length > 1 ? arguments[1] : {}, peg$FAILED = {}, peg$startRuleFunctions = { visualFormatStringExt: peg$parsevisualFormatStringExt }, peg$startRuleFunction = peg$parsevisualFormatStringExt, peg$c0 = peg$FAILED, peg$c1 = 'C:', peg$c2 = { type: 'literal', value: 'C:', description: '"C:"' }, peg$c3 = [], peg$c4 = null, peg$c5 = function (view, attribute, attributes, comments) {
            return {
                type: 'attribute',
                view: view.view,
                attributes: [attribute].concat(attributes),
            };
        }, peg$c6 = function (attr, predicates) {
            return { attr: attr, predicates: predicates };
        }, peg$c7 = ':', peg$c8 = { type: 'literal', value: ':', description: '":"' }, peg$c9 = function (o, superto, view, views, tosuper, comments) {
            return {
                type: 'vfl',
                orientation: o ? o[0] : 'horizontal',
                cascade: (superto || []).concat(view, [].concat.apply([], views), tosuper || []),
            };
        }, peg$c10 = 'HV', peg$c11 = { type: 'literal', value: 'HV', description: '"HV"' }, peg$c12 = function () {
            return 'horzvert';
        }, peg$c13 = 'H', peg$c14 = { type: 'literal', value: 'H', description: '"H"' }, peg$c15 = function () {
            return 'horizontal';
        }, peg$c16 = 'V', peg$c17 = { type: 'literal', value: 'V', description: '"V"' }, peg$c18 = function () {
            return 'vertical';
        }, peg$c19 = 'Z', peg$c20 = { type: 'literal', value: 'Z', description: '"Z"' }, peg$c21 = function () {
            return 'zIndex';
        }, peg$c22 = ' ', peg$c23 = { type: 'literal', value: ' ', description: '" "' }, peg$c24 = '//', peg$c25 = { type: 'literal', value: '//', description: '"//"' }, peg$c26 = { type: 'any', description: 'any character' }, peg$c27 = '|', peg$c28 = { type: 'literal', value: '|', description: '"|"' }, peg$c29 = function () {
            return { view: null };
        }, peg$c30 = '[', peg$c31 = { type: 'literal', value: '[', description: '"["' }, peg$c32 = ',', peg$c33 = { type: 'literal', value: ',', description: '","' }, peg$c34 = ']', peg$c35 = { type: 'literal', value: ']', description: '"]"' }, peg$c36 = function (view, views) {
            return views.length ? [view].concat([].concat.apply([], views)) : view;
        }, peg$c37 = function (view, predicates, cascadedViews) {
            return extend(extend(view, predicates ? { constraints: predicates } : {}), cascadedViews
                ? {
                    cascade: cascadedViews,
                }
                : {});
        }, peg$c38 = function (views, connection) {
            return [].concat([].concat.apply([], views), [connection]);
        }, peg$c39 = '->', peg$c40 = { type: 'literal', value: '->', description: '"->"' }, peg$c41 = function () {
            return [{ relation: 'none' }];
        }, peg$c42 = '-', peg$c43 = { type: 'literal', value: '-', description: '"-"' }, peg$c44 = function (predicateList) {
            return predicateList;
        }, peg$c45 = function () {
            return [{ relation: 'equ', constant: 'default' }];
        }, peg$c46 = '~', peg$c47 = { type: 'literal', value: '~', description: '"~"' }, peg$c48 = function () {
            return [{ relation: 'equ', equalSpacing: true }];
        }, peg$c49 = '', peg$c50 = function () {
            return [{ relation: 'equ', constant: 0 }];
        }, peg$c51 = function (p) {
            return [{ relation: 'equ', multiplier: p.multiplier }];
        }, peg$c52 = function (n) {
            return [{ relation: 'equ', constant: n }];
        }, peg$c53 = '(', peg$c54 = { type: 'literal', value: '(', description: '"("' }, peg$c55 = ')', peg$c56 = { type: 'literal', value: ')', description: '")"' }, peg$c57 = function (p, ps) {
            return [p].concat(ps.map(function (p) {
                return p[1];
            }));
        }, peg$c58 = '@', peg$c59 = { type: 'literal', value: '@', description: '"@"' }, peg$c60 = function (r, o, p) {
            return extend({ relation: 'equ' }, r || {}, o, p ? p[1] : {});
        }, peg$c61 = function (r, o, p) {
            return extend({ relation: 'equ', equalSpacing: true }, r || {}, o, p ? p[1] : {});
        }, peg$c62 = '==', peg$c63 = { type: 'literal', value: '==', description: '"=="' }, peg$c64 = function () {
            return { relation: 'equ' };
        }, peg$c65 = '<=', peg$c66 = { type: 'literal', value: '<=', description: '"<="' }, peg$c67 = function () {
            return { relation: 'leq' };
        }, peg$c68 = '>=', peg$c69 = { type: 'literal', value: '>=', description: '">="' }, peg$c70 = function () {
            return { relation: 'geq' };
        }, peg$c71 = /^[0-9]/, peg$c72 = { type: 'class', value: '[0-9]', description: '[0-9]' }, peg$c73 = function (digits) {
            return { priority: parseInt(digits.join(''), 10) };
        }, peg$c74 = function (n) {
            return { constant: n };
        }, peg$c75 = function (n) {
            return { constant: -n };
        }, peg$c76 = '+', peg$c77 = { type: 'literal', value: '+', description: '"+"' }, peg$c78 = '%', peg$c79 = { type: 'literal', value: '%', description: '"%"' }, peg$c80 = function (n) {
            return { view: null, multiplier: n / 100 };
        }, peg$c81 = function (n) {
            return { view: null, multiplier: n / -100 };
        }, peg$c82 = function (vn, a, m, c) {
            return {
                view: vn.view,
                attribute: a ? a : undefined,
                multiplier: m ? m : 1,
                constant: c ? c : undefined,
            };
        }, peg$c83 = '.left', peg$c84 = { type: 'literal', value: '.left', description: '".left"' }, peg$c85 = function () {
            return 'left';
        }, peg$c86 = '.right', peg$c87 = { type: 'literal', value: '.right', description: '".right"' }, peg$c88 = function () {
            return 'right';
        }, peg$c89 = '.top', peg$c90 = { type: 'literal', value: '.top', description: '".top"' }, peg$c91 = function () {
            return 'top';
        }, peg$c92 = '.bottom', peg$c93 = { type: 'literal', value: '.bottom', description: '".bottom"' }, peg$c94 = function () {
            return 'bottom';
        }, peg$c95 = '.width', peg$c96 = { type: 'literal', value: '.width', description: '".width"' }, peg$c97 = function () {
            return 'width';
        }, peg$c98 = '.height', peg$c99 = { type: 'literal', value: '.height', description: '".height"' }, peg$c100 = function () {
            return 'height';
        }, peg$c101 = '.centerX', peg$c102 = { type: 'literal', value: '.centerX', description: '".centerX"' }, peg$c103 = function () {
            return 'centerX';
        }, peg$c104 = '.centerY', peg$c105 = { type: 'literal', value: '.centerY', description: '".centerY"' }, peg$c106 = function () {
            return 'centerY';
        }, peg$c107 = '/', peg$c108 = { type: 'literal', value: '/', description: '"/"' }, peg$c109 = function (n) {
            return 1 / n;
        }, peg$c110 = '/+', peg$c111 = { type: 'literal', value: '/+', description: '"/+"' }, peg$c112 = '/-', peg$c113 = { type: 'literal', value: '/-', description: '"/-"' }, peg$c114 = function (n) {
            return -1 / n;
        }, peg$c115 = '*', peg$c116 = { type: 'literal', value: '*', description: '"*"' }, peg$c117 = function (n) {
            return n;
        }, peg$c118 = '*+', peg$c119 = { type: 'literal', value: '*+', description: '"*+"' }, peg$c120 = '*-', peg$c121 = { type: 'literal', value: '*-', description: '"*-"' }, peg$c122 = function (n) {
            return -n;
        }, peg$c123 = /^[a-zA-Z_]/, peg$c124 = { type: 'class', value: '[a-zA-Z_]', description: '[a-zA-Z_]' }, peg$c125 = /^[a-zA-Z0-9_]/, peg$c126 = { type: 'class', value: '[a-zA-Z0-9_]', description: '[a-zA-Z0-9_]' }, peg$c127 = function (f, v, r) {
            return { view: f + v, range: r, $parserOffset: offset() };
        }, peg$c128 = function (f, v) {
            return { view: f + v, $parserOffset: offset() };
        }, peg$c129 = '..', peg$c130 = { type: 'literal', value: '..', description: '".."' }, peg$c131 = function (d) {
            return parseInt(d);
        }, peg$c132 = '.', peg$c133 = { type: 'literal', value: '.', description: '"."' }, peg$c134 = function (digits, decimals) {
            return parseFloat(digits.concat('.').concat(decimals).join(''));
        }, peg$c135 = function (digits) {
            return parseInt(digits.join(''), 10);
        }, peg$currPos = 0, peg$reportedPos = 0, peg$cachedPos = 0, peg$cachedPosDetails = { line: 1, column: 1, seenCR: false }, peg$maxFailPos = 0, peg$maxFailExpected = [], peg$silentFails = 0, peg$result;
        if ('startRule' in options) {
            if (!(options.startRule in peg$startRuleFunctions)) {
                throw new Error('Can\'t start parsing from rule "' + options.startRule + '".');
            }
            peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
        }
        function text() {
            return input.substring(peg$reportedPos, peg$currPos);
        }
        function offset() {
            return peg$reportedPos;
        }
        function line() {
            return peg$computePosDetails(peg$reportedPos).line;
        }
        function column() {
            return peg$computePosDetails(peg$reportedPos).column;
        }
        function expected(description) {
            throw peg$buildException(null, [{ type: 'other', description: description }], peg$reportedPos);
        }
        function error(message) {
            throw peg$buildException(message, null, peg$reportedPos);
        }
        function peg$computePosDetails(pos) {
            function advance(details, startPos, endPos) {
                var p, ch;
                for (p = startPos; p < endPos; p++) {
                    ch = input.charAt(p);
                    if (ch === '\n') {
                        if (!details.seenCR) {
                            details.line++;
                        }
                        details.column = 1;
                        details.seenCR = false;
                    }
                    else if (ch === '\r' || ch === '\u2028' || ch === '\u2029') {
                        details.line++;
                        details.column = 1;
                        details.seenCR = true;
                    }
                    else {
                        details.column++;
                        details.seenCR = false;
                    }
                }
            }
            if (peg$cachedPos !== pos) {
                if (peg$cachedPos > pos) {
                    peg$cachedPos = 0;
                    peg$cachedPosDetails = { line: 1, column: 1, seenCR: false };
                }
                advance(peg$cachedPosDetails, peg$cachedPos, pos);
                peg$cachedPos = pos;
            }
            return peg$cachedPosDetails;
        }
        function peg$fail(expected) {
            if (peg$currPos < peg$maxFailPos) {
                return;
            }
            if (peg$currPos > peg$maxFailPos) {
                peg$maxFailPos = peg$currPos;
                peg$maxFailExpected = [];
            }
            peg$maxFailExpected.push(expected);
        }
        function peg$buildException(message, expected, pos) {
            function cleanupExpected(expected) {
                var i = 1;
                expected.sort(function (a, b) {
                    if (a.description < b.description) {
                        return -1;
                    }
                    else if (a.description > b.description) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                });
                while (i < expected.length) {
                    if (expected[i - 1] === expected[i]) {
                        expected.splice(i, 1);
                    }
                    else {
                        i++;
                    }
                }
            }
            function buildMessage(expected, found) {
                function stringEscape(s) {
                    function hex(ch) {
                        return ch.charCodeAt(0).toString(16).toUpperCase();
                    }
                    return s
                        .replace(/\\/g, '\\\\')
                        .replace(/"/g, '\\"')
                        .replace(/\x08/g, '\\b')
                        .replace(/\t/g, '\\t')
                        .replace(/\n/g, '\\n')
                        .replace(/\f/g, '\\f')
                        .replace(/\r/g, '\\r')
                        .replace(/[\x00-\x07\x0B\x0E\x0F]/g, function (ch) {
                        return '\\x0' + hex(ch);
                    })
                        .replace(/[\x10-\x1F\x80-\xFF]/g, function (ch) {
                        return '\\x' + hex(ch);
                    })
                        .replace(/[\u0180-\u0FFF]/g, function (ch) {
                        return '\\u0' + hex(ch);
                    })
                        .replace(/[\u1080-\uFFFF]/g, function (ch) {
                        return '\\u' + hex(ch);
                    });
                }
                var expectedDescs = new Array(expected.length), expectedDesc, foundDesc, i;
                for (i = 0; i < expected.length; i++) {
                    expectedDescs[i] = expected[i].description;
                }
                expectedDesc =
                    expected.length > 1
                        ? expectedDescs.slice(0, -1).join(', ') + ' or ' + expectedDescs[expected.length - 1]
                        : expectedDescs[0];
                foundDesc = found ? '"' + stringEscape(found) + '"' : 'end of input';
                return 'Expected ' + expectedDesc + ' but ' + foundDesc + ' found.';
            }
            var posDetails = peg$computePosDetails(pos), found = pos < input.length ? input.charAt(pos) : null;
            if (expected !== null) {
                cleanupExpected(expected);
            }
            return new SyntaxError(message !== null ? message : buildMessage(expected, found), expected, found, pos, posDetails.line, posDetails.column);
        }
        function peg$parsevisualFormatStringExt() {
            var s0;
            s0 = peg$parsevisualFormatString();
            if (s0 === peg$FAILED) {
                s0 = peg$parsevisualFormatStringConstraintExpression();
            }
            return s0;
        }
        function peg$parsevisualFormatStringConstraintExpression() {
            var s0, s1, s2, s3, s4, s5;
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c1) {
                s1 = peg$c1;
                peg$currPos += 2;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c2);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parseviewName();
                if (s2 !== peg$FAILED) {
                    s3 = peg$parseattributePredicate();
                    if (s3 !== peg$FAILED) {
                        s4 = [];
                        s5 = peg$parseattributePredicate();
                        while (s5 !== peg$FAILED) {
                            s4.push(s5);
                            s5 = peg$parseattributePredicate();
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parsecomments();
                            if (s5 === peg$FAILED) {
                                s5 = peg$c4;
                            }
                            if (s5 !== peg$FAILED) {
                                peg$reportedPos = s0;
                                s1 = peg$c5(s2, s3, s4, s5);
                                s0 = s1;
                            }
                            else {
                                peg$currPos = s0;
                                s0 = peg$c0;
                            }
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parseattributePredicate() {
            var s0, s1, s2;
            s0 = peg$currPos;
            s1 = peg$parseattribute();
            if (s1 !== peg$FAILED) {
                s2 = peg$parsepredicateListWithParens();
                if (s2 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c6(s1, s2);
                    s0 = s1;
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parsevisualFormatString() {
            var s0, s1, s2, s3, s4, s5, s6, s7;
            s0 = peg$currPos;
            s1 = peg$currPos;
            s2 = peg$parseorientation();
            if (s2 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 58) {
                    s3 = peg$c7;
                    peg$currPos++;
                }
                else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c8);
                    }
                }
                if (s3 !== peg$FAILED) {
                    s2 = [s2, s3];
                    s1 = s2;
                }
                else {
                    peg$currPos = s1;
                    s1 = peg$c0;
                }
            }
            else {
                peg$currPos = s1;
                s1 = peg$c0;
            }
            if (s1 === peg$FAILED) {
                s1 = peg$c4;
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$currPos;
                s3 = peg$parsesuperview();
                if (s3 !== peg$FAILED) {
                    s4 = peg$parseconnection();
                    if (s4 !== peg$FAILED) {
                        s3 = [s3, s4];
                        s2 = s3;
                    }
                    else {
                        peg$currPos = s2;
                        s2 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s2;
                    s2 = peg$c0;
                }
                if (s2 === peg$FAILED) {
                    s2 = peg$c4;
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parseviewGroup();
                    if (s3 !== peg$FAILED) {
                        s4 = [];
                        s5 = peg$currPos;
                        s6 = peg$parseconnection();
                        if (s6 !== peg$FAILED) {
                            s7 = peg$parseviewGroup();
                            if (s7 !== peg$FAILED) {
                                s6 = [s6, s7];
                                s5 = s6;
                            }
                            else {
                                peg$currPos = s5;
                                s5 = peg$c0;
                            }
                        }
                        else {
                            peg$currPos = s5;
                            s5 = peg$c0;
                        }
                        while (s5 !== peg$FAILED) {
                            s4.push(s5);
                            s5 = peg$currPos;
                            s6 = peg$parseconnection();
                            if (s6 !== peg$FAILED) {
                                s7 = peg$parseviewGroup();
                                if (s7 !== peg$FAILED) {
                                    s6 = [s6, s7];
                                    s5 = s6;
                                }
                                else {
                                    peg$currPos = s5;
                                    s5 = peg$c0;
                                }
                            }
                            else {
                                peg$currPos = s5;
                                s5 = peg$c0;
                            }
                        }
                        if (s4 !== peg$FAILED) {
                            s5 = peg$currPos;
                            s6 = peg$parseconnection();
                            if (s6 !== peg$FAILED) {
                                s7 = peg$parsesuperview();
                                if (s7 !== peg$FAILED) {
                                    s6 = [s6, s7];
                                    s5 = s6;
                                }
                                else {
                                    peg$currPos = s5;
                                    s5 = peg$c0;
                                }
                            }
                            else {
                                peg$currPos = s5;
                                s5 = peg$c0;
                            }
                            if (s5 === peg$FAILED) {
                                s5 = peg$c4;
                            }
                            if (s5 !== peg$FAILED) {
                                s6 = peg$parsecomments();
                                if (s6 === peg$FAILED) {
                                    s6 = peg$c4;
                                }
                                if (s6 !== peg$FAILED) {
                                    peg$reportedPos = s0;
                                    s1 = peg$c9(s1, s2, s3, s4, s5, s6);
                                    s0 = s1;
                                }
                                else {
                                    peg$currPos = s0;
                                    s0 = peg$c0;
                                }
                            }
                            else {
                                peg$currPos = s0;
                                s0 = peg$c0;
                            }
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parseorientation() {
            var s0, s1;
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c10) {
                s1 = peg$c10;
                peg$currPos += 2;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c11);
                }
            }
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c12();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 72) {
                    s1 = peg$c13;
                    peg$currPos++;
                }
                else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c14);
                    }
                }
                if (s1 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c15();
                }
                s0 = s1;
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 86) {
                        s1 = peg$c16;
                        peg$currPos++;
                    }
                    else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c17);
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c18();
                    }
                    s0 = s1;
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 90) {
                            s1 = peg$c19;
                            peg$currPos++;
                        }
                        else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c20);
                            }
                        }
                        if (s1 !== peg$FAILED) {
                            peg$reportedPos = s0;
                            s1 = peg$c21();
                        }
                        s0 = s1;
                    }
                }
            }
            return s0;
        }
        function peg$parsecomments() {
            var s0, s1, s2, s3, s4;
            s0 = peg$currPos;
            s1 = [];
            if (input.charCodeAt(peg$currPos) === 32) {
                s2 = peg$c22;
                peg$currPos++;
            }
            else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c23);
                }
            }
            while (s2 !== peg$FAILED) {
                s1.push(s2);
                if (input.charCodeAt(peg$currPos) === 32) {
                    s2 = peg$c22;
                    peg$currPos++;
                }
                else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c23);
                    }
                }
            }
            if (s1 !== peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c24) {
                    s2 = peg$c24;
                    peg$currPos += 2;
                }
                else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c25);
                    }
                }
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    if (input.length > peg$currPos) {
                        s4 = input.charAt(peg$currPos);
                        peg$currPos++;
                    }
                    else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c26);
                        }
                    }
                    while (s4 !== peg$FAILED) {
                        s3.push(s4);
                        if (input.length > peg$currPos) {
                            s4 = input.charAt(peg$currPos);
                            peg$currPos++;
                        }
                        else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c26);
                            }
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        s1 = [s1, s2, s3];
                        s0 = s1;
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parsesuperview() {
            var s0, s1;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 124) {
                s1 = peg$c27;
                peg$currPos++;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c28);
                }
            }
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c29();
            }
            s0 = s1;
            return s0;
        }
        function peg$parseviewGroup() {
            var s0, s1, s2, s3, s4, s5, s6;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 91) {
                s1 = peg$c30;
                peg$currPos++;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c31);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parseview();
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    s4 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 44) {
                        s5 = peg$c32;
                        peg$currPos++;
                    }
                    else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c33);
                        }
                    }
                    if (s5 !== peg$FAILED) {
                        s6 = peg$parseview();
                        if (s6 !== peg$FAILED) {
                            s5 = [s5, s6];
                            s4 = s5;
                        }
                        else {
                            peg$currPos = s4;
                            s4 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s4;
                        s4 = peg$c0;
                    }
                    while (s4 !== peg$FAILED) {
                        s3.push(s4);
                        s4 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 44) {
                            s5 = peg$c32;
                            peg$currPos++;
                        }
                        else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c33);
                            }
                        }
                        if (s5 !== peg$FAILED) {
                            s6 = peg$parseview();
                            if (s6 !== peg$FAILED) {
                                s5 = [s5, s6];
                                s4 = s5;
                            }
                            else {
                                peg$currPos = s4;
                                s4 = peg$c0;
                            }
                        }
                        else {
                            peg$currPos = s4;
                            s4 = peg$c0;
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 93) {
                            s4 = peg$c34;
                            peg$currPos++;
                        }
                        else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c35);
                            }
                        }
                        if (s4 !== peg$FAILED) {
                            peg$reportedPos = s0;
                            s1 = peg$c36(s2, s3);
                            s0 = s1;
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parseview() {
            var s0, s1, s2, s3;
            s0 = peg$currPos;
            s1 = peg$parseviewNameRange();
            if (s1 !== peg$FAILED) {
                s2 = peg$parsepredicateListWithParens();
                if (s2 === peg$FAILED) {
                    s2 = peg$c4;
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parsecascadedViews();
                    if (s3 === peg$FAILED) {
                        s3 = peg$c4;
                    }
                    if (s3 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c37(s1, s2, s3);
                        s0 = s1;
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parsecascadedViews() {
            var s0, s1, s2, s3, s4, s5;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 58) {
                s1 = peg$c7;
                peg$currPos++;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c8);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = [];
                s3 = peg$currPos;
                s4 = peg$parseconnection();
                if (s4 !== peg$FAILED) {
                    s5 = peg$parseviewGroup();
                    if (s5 !== peg$FAILED) {
                        s4 = [s4, s5];
                        s3 = s4;
                    }
                    else {
                        peg$currPos = s3;
                        s3 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s3;
                    s3 = peg$c0;
                }
                if (s3 !== peg$FAILED) {
                    while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        s3 = peg$currPos;
                        s4 = peg$parseconnection();
                        if (s4 !== peg$FAILED) {
                            s5 = peg$parseviewGroup();
                            if (s5 !== peg$FAILED) {
                                s4 = [s4, s5];
                                s3 = s4;
                            }
                            else {
                                peg$currPos = s3;
                                s3 = peg$c0;
                            }
                        }
                        else {
                            peg$currPos = s3;
                            s3 = peg$c0;
                        }
                    }
                }
                else {
                    s2 = peg$c0;
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parseconnection();
                    if (s3 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c38(s2, s3);
                        s0 = s1;
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parseconnection() {
            var s0, s1, s2, s3;
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c39) {
                s1 = peg$c39;
                peg$currPos += 2;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c40);
                }
            }
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c41();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 45) {
                    s1 = peg$c42;
                    peg$currPos++;
                }
                else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c43);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsepredicateList();
                    if (s2 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 45) {
                            s3 = peg$c42;
                            peg$currPos++;
                        }
                        else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c43);
                            }
                        }
                        if (s3 !== peg$FAILED) {
                            peg$reportedPos = s0;
                            s1 = peg$c44(s2);
                            s0 = s1;
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 45) {
                        s1 = peg$c42;
                        peg$currPos++;
                    }
                    else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c43);
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c45();
                    }
                    s0 = s1;
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 126) {
                            s1 = peg$c46;
                            peg$currPos++;
                        }
                        else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c47);
                            }
                        }
                        if (s1 !== peg$FAILED) {
                            s2 = peg$parseequalSpacingPredicateList();
                            if (s2 !== peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 126) {
                                    s3 = peg$c46;
                                    peg$currPos++;
                                }
                                else {
                                    s3 = peg$FAILED;
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$c47);
                                    }
                                }
                                if (s3 !== peg$FAILED) {
                                    peg$reportedPos = s0;
                                    s1 = peg$c44(s2);
                                    s0 = s1;
                                }
                                else {
                                    peg$currPos = s0;
                                    s0 = peg$c0;
                                }
                            }
                            else {
                                peg$currPos = s0;
                                s0 = peg$c0;
                            }
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                        if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            if (input.charCodeAt(peg$currPos) === 126) {
                                s1 = peg$c46;
                                peg$currPos++;
                            }
                            else {
                                s1 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c47);
                                }
                            }
                            if (s1 !== peg$FAILED) {
                                peg$reportedPos = s0;
                                s1 = peg$c48();
                            }
                            s0 = s1;
                            if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                s1 = peg$c49;
                                if (s1 !== peg$FAILED) {
                                    peg$reportedPos = s0;
                                    s1 = peg$c50();
                                }
                                s0 = s1;
                            }
                        }
                    }
                }
            }
            return s0;
        }
        function peg$parsepredicateList() {
            var s0;
            s0 = peg$parsesimplePredicate();
            if (s0 === peg$FAILED) {
                s0 = peg$parsepredicateListWithParens();
            }
            return s0;
        }
        function peg$parsesimplePredicate() {
            var s0, s1;
            s0 = peg$currPos;
            s1 = peg$parsepercentage();
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c51(s1);
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parsenumber();
                if (s1 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c52(s1);
                }
                s0 = s1;
            }
            return s0;
        }
        function peg$parsepredicateListWithParens() {
            var s0, s1, s2, s3, s4, s5, s6;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 40) {
                s1 = peg$c53;
                peg$currPos++;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c54);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parsepredicate();
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    s4 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 44) {
                        s5 = peg$c32;
                        peg$currPos++;
                    }
                    else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c33);
                        }
                    }
                    if (s5 !== peg$FAILED) {
                        s6 = peg$parsepredicate();
                        if (s6 !== peg$FAILED) {
                            s5 = [s5, s6];
                            s4 = s5;
                        }
                        else {
                            peg$currPos = s4;
                            s4 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s4;
                        s4 = peg$c0;
                    }
                    while (s4 !== peg$FAILED) {
                        s3.push(s4);
                        s4 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 44) {
                            s5 = peg$c32;
                            peg$currPos++;
                        }
                        else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c33);
                            }
                        }
                        if (s5 !== peg$FAILED) {
                            s6 = peg$parsepredicate();
                            if (s6 !== peg$FAILED) {
                                s5 = [s5, s6];
                                s4 = s5;
                            }
                            else {
                                peg$currPos = s4;
                                s4 = peg$c0;
                            }
                        }
                        else {
                            peg$currPos = s4;
                            s4 = peg$c0;
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 41) {
                            s4 = peg$c55;
                            peg$currPos++;
                        }
                        else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c56);
                            }
                        }
                        if (s4 !== peg$FAILED) {
                            peg$reportedPos = s0;
                            s1 = peg$c57(s2, s3);
                            s0 = s1;
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parsepredicate() {
            var s0, s1, s2, s3, s4, s5;
            s0 = peg$currPos;
            s1 = peg$parserelation();
            if (s1 === peg$FAILED) {
                s1 = peg$c4;
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parseobjectOfPredicate();
                if (s2 !== peg$FAILED) {
                    s3 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 64) {
                        s4 = peg$c58;
                        peg$currPos++;
                    }
                    else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c59);
                        }
                    }
                    if (s4 !== peg$FAILED) {
                        s5 = peg$parsepriority();
                        if (s5 !== peg$FAILED) {
                            s4 = [s4, s5];
                            s3 = s4;
                        }
                        else {
                            peg$currPos = s3;
                            s3 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s3;
                        s3 = peg$c0;
                    }
                    if (s3 === peg$FAILED) {
                        s3 = peg$c4;
                    }
                    if (s3 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c60(s1, s2, s3);
                        s0 = s1;
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parseequalSpacingPredicateList() {
            var s0, s1, s2, s3, s4, s5, s6;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 40) {
                s1 = peg$c53;
                peg$currPos++;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c54);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parseequalSpacingPredicate();
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    s4 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 44) {
                        s5 = peg$c32;
                        peg$currPos++;
                    }
                    else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c33);
                        }
                    }
                    if (s5 !== peg$FAILED) {
                        s6 = peg$parseequalSpacingPredicate();
                        if (s6 !== peg$FAILED) {
                            s5 = [s5, s6];
                            s4 = s5;
                        }
                        else {
                            peg$currPos = s4;
                            s4 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s4;
                        s4 = peg$c0;
                    }
                    while (s4 !== peg$FAILED) {
                        s3.push(s4);
                        s4 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 44) {
                            s5 = peg$c32;
                            peg$currPos++;
                        }
                        else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c33);
                            }
                        }
                        if (s5 !== peg$FAILED) {
                            s6 = peg$parseequalSpacingPredicate();
                            if (s6 !== peg$FAILED) {
                                s5 = [s5, s6];
                                s4 = s5;
                            }
                            else {
                                peg$currPos = s4;
                                s4 = peg$c0;
                            }
                        }
                        else {
                            peg$currPos = s4;
                            s4 = peg$c0;
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 41) {
                            s4 = peg$c55;
                            peg$currPos++;
                        }
                        else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c56);
                            }
                        }
                        if (s4 !== peg$FAILED) {
                            peg$reportedPos = s0;
                            s1 = peg$c57(s2, s3);
                            s0 = s1;
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parseequalSpacingPredicate() {
            var s0, s1, s2, s3, s4, s5;
            s0 = peg$currPos;
            s1 = peg$parserelation();
            if (s1 === peg$FAILED) {
                s1 = peg$c4;
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parseobjectOfPredicate();
                if (s2 !== peg$FAILED) {
                    s3 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 64) {
                        s4 = peg$c58;
                        peg$currPos++;
                    }
                    else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c59);
                        }
                    }
                    if (s4 !== peg$FAILED) {
                        s5 = peg$parsepriority();
                        if (s5 !== peg$FAILED) {
                            s4 = [s4, s5];
                            s3 = s4;
                        }
                        else {
                            peg$currPos = s3;
                            s3 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s3;
                        s3 = peg$c0;
                    }
                    if (s3 === peg$FAILED) {
                        s3 = peg$c4;
                    }
                    if (s3 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c61(s1, s2, s3);
                        s0 = s1;
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parserelation() {
            var s0, s1;
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c62) {
                s1 = peg$c62;
                peg$currPos += 2;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c63);
                }
            }
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c64();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 2) === peg$c65) {
                    s1 = peg$c65;
                    peg$currPos += 2;
                }
                else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c66);
                    }
                }
                if (s1 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c67();
                }
                s0 = s1;
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.substr(peg$currPos, 2) === peg$c68) {
                        s1 = peg$c68;
                        peg$currPos += 2;
                    }
                    else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c69);
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c70();
                    }
                    s0 = s1;
                }
            }
            return s0;
        }
        function peg$parseobjectOfPredicate() {
            var s0;
            s0 = peg$parsepercentage();
            if (s0 === peg$FAILED) {
                s0 = peg$parseconstant();
                if (s0 === peg$FAILED) {
                    s0 = peg$parseviewPredicate();
                }
            }
            return s0;
        }
        function peg$parsepriority() {
            var s0, s1, s2;
            s0 = peg$currPos;
            s1 = [];
            if (peg$c71.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
            }
            else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c72);
                }
            }
            if (s2 !== peg$FAILED) {
                while (s2 !== peg$FAILED) {
                    s1.push(s2);
                    if (peg$c71.test(input.charAt(peg$currPos))) {
                        s2 = input.charAt(peg$currPos);
                        peg$currPos++;
                    }
                    else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c72);
                        }
                    }
                }
            }
            else {
                s1 = peg$c0;
            }
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c73(s1);
            }
            s0 = s1;
            return s0;
        }
        function peg$parseconstant() {
            var s0, s1, s2;
            s0 = peg$currPos;
            s1 = peg$parsenumber();
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c74(s1);
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 45) {
                    s1 = peg$c42;
                    peg$currPos++;
                }
                else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c43);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsenumber();
                    if (s2 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c75(s2);
                        s0 = s1;
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 43) {
                        s1 = peg$c76;
                        peg$currPos++;
                    }
                    else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c77);
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parsenumber();
                        if (s2 !== peg$FAILED) {
                            peg$reportedPos = s0;
                            s1 = peg$c74(s2);
                            s0 = s1;
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
            }
            return s0;
        }
        function peg$parsepercentage() {
            var s0, s1, s2, s3;
            s0 = peg$currPos;
            s1 = peg$parsenumber();
            if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 37) {
                    s2 = peg$c78;
                    peg$currPos++;
                }
                else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c79);
                    }
                }
                if (s2 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c80(s1);
                    s0 = s1;
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 45) {
                    s1 = peg$c42;
                    peg$currPos++;
                }
                else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c43);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsenumber();
                    if (s2 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 37) {
                            s3 = peg$c78;
                            peg$currPos++;
                        }
                        else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c79);
                            }
                        }
                        if (s3 !== peg$FAILED) {
                            peg$reportedPos = s0;
                            s1 = peg$c81(s2);
                            s0 = s1;
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 43) {
                        s1 = peg$c76;
                        peg$currPos++;
                    }
                    else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c77);
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parsenumber();
                        if (s2 !== peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 37) {
                                s3 = peg$c78;
                                peg$currPos++;
                            }
                            else {
                                s3 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c79);
                                }
                            }
                            if (s3 !== peg$FAILED) {
                                peg$reportedPos = s0;
                                s1 = peg$c80(s2);
                                s0 = s1;
                            }
                            else {
                                peg$currPos = s0;
                                s0 = peg$c0;
                            }
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
            }
            return s0;
        }
        function peg$parseviewPredicate() {
            var s0, s1, s2, s3, s4;
            s0 = peg$currPos;
            s1 = peg$parseviewName();
            if (s1 !== peg$FAILED) {
                s2 = peg$parseattribute();
                if (s2 === peg$FAILED) {
                    s2 = peg$c4;
                }
                if (s2 !== peg$FAILED) {
                    s3 = peg$parsemultiplier();
                    if (s3 === peg$FAILED) {
                        s3 = peg$c4;
                    }
                    if (s3 !== peg$FAILED) {
                        s4 = peg$parseconstantExpr();
                        if (s4 === peg$FAILED) {
                            s4 = peg$c4;
                        }
                        if (s4 !== peg$FAILED) {
                            peg$reportedPos = s0;
                            s1 = peg$c82(s1, s2, s3, s4);
                            s0 = s1;
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parseattribute() {
            var s0, s1;
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 5) === peg$c83) {
                s1 = peg$c83;
                peg$currPos += 5;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c84);
                }
            }
            if (s1 !== peg$FAILED) {
                peg$reportedPos = s0;
                s1 = peg$c85();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 6) === peg$c86) {
                    s1 = peg$c86;
                    peg$currPos += 6;
                }
                else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c87);
                    }
                }
                if (s1 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c88();
                }
                s0 = s1;
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.substr(peg$currPos, 4) === peg$c89) {
                        s1 = peg$c89;
                        peg$currPos += 4;
                    }
                    else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c90);
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c91();
                    }
                    s0 = s1;
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.substr(peg$currPos, 7) === peg$c92) {
                            s1 = peg$c92;
                            peg$currPos += 7;
                        }
                        else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c93);
                            }
                        }
                        if (s1 !== peg$FAILED) {
                            peg$reportedPos = s0;
                            s1 = peg$c94();
                        }
                        s0 = s1;
                        if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            if (input.substr(peg$currPos, 6) === peg$c95) {
                                s1 = peg$c95;
                                peg$currPos += 6;
                            }
                            else {
                                s1 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c96);
                                }
                            }
                            if (s1 !== peg$FAILED) {
                                peg$reportedPos = s0;
                                s1 = peg$c97();
                            }
                            s0 = s1;
                            if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                if (input.substr(peg$currPos, 7) === peg$c98) {
                                    s1 = peg$c98;
                                    peg$currPos += 7;
                                }
                                else {
                                    s1 = peg$FAILED;
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$c99);
                                    }
                                }
                                if (s1 !== peg$FAILED) {
                                    peg$reportedPos = s0;
                                    s1 = peg$c100();
                                }
                                s0 = s1;
                                if (s0 === peg$FAILED) {
                                    s0 = peg$currPos;
                                    if (input.substr(peg$currPos, 8) === peg$c101) {
                                        s1 = peg$c101;
                                        peg$currPos += 8;
                                    }
                                    else {
                                        s1 = peg$FAILED;
                                        if (peg$silentFails === 0) {
                                            peg$fail(peg$c102);
                                        }
                                    }
                                    if (s1 !== peg$FAILED) {
                                        peg$reportedPos = s0;
                                        s1 = peg$c103();
                                    }
                                    s0 = s1;
                                    if (s0 === peg$FAILED) {
                                        s0 = peg$currPos;
                                        if (input.substr(peg$currPos, 8) === peg$c104) {
                                            s1 = peg$c104;
                                            peg$currPos += 8;
                                        }
                                        else {
                                            s1 = peg$FAILED;
                                            if (peg$silentFails === 0) {
                                                peg$fail(peg$c105);
                                            }
                                        }
                                        if (s1 !== peg$FAILED) {
                                            peg$reportedPos = s0;
                                            s1 = peg$c106();
                                        }
                                        s0 = s1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return s0;
        }
        function peg$parsemultiplier() {
            var s0, s1, s2;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 47) {
                s1 = peg$c107;
                peg$currPos++;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c108);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parsenumber();
                if (s2 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c109(s2);
                    s0 = s1;
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 2) === peg$c110) {
                    s1 = peg$c110;
                    peg$currPos += 2;
                }
                else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c111);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsenumber();
                    if (s2 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c109(s2);
                        s0 = s1;
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.substr(peg$currPos, 2) === peg$c112) {
                        s1 = peg$c112;
                        peg$currPos += 2;
                    }
                    else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c113);
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        s2 = peg$parsenumber();
                        if (s2 !== peg$FAILED) {
                            peg$reportedPos = s0;
                            s1 = peg$c114(s2);
                            s0 = s1;
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 42) {
                            s1 = peg$c115;
                            peg$currPos++;
                        }
                        else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c116);
                            }
                        }
                        if (s1 !== peg$FAILED) {
                            s2 = peg$parsenumber();
                            if (s2 !== peg$FAILED) {
                                peg$reportedPos = s0;
                                s1 = peg$c117(s2);
                                s0 = s1;
                            }
                            else {
                                peg$currPos = s0;
                                s0 = peg$c0;
                            }
                        }
                        else {
                            peg$currPos = s0;
                            s0 = peg$c0;
                        }
                        if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            if (input.substr(peg$currPos, 2) === peg$c118) {
                                s1 = peg$c118;
                                peg$currPos += 2;
                            }
                            else {
                                s1 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c119);
                                }
                            }
                            if (s1 !== peg$FAILED) {
                                s2 = peg$parsenumber();
                                if (s2 !== peg$FAILED) {
                                    peg$reportedPos = s0;
                                    s1 = peg$c117(s2);
                                    s0 = s1;
                                }
                                else {
                                    peg$currPos = s0;
                                    s0 = peg$c0;
                                }
                            }
                            else {
                                peg$currPos = s0;
                                s0 = peg$c0;
                            }
                            if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                if (input.substr(peg$currPos, 2) === peg$c120) {
                                    s1 = peg$c120;
                                    peg$currPos += 2;
                                }
                                else {
                                    s1 = peg$FAILED;
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$c121);
                                    }
                                }
                                if (s1 !== peg$FAILED) {
                                    s2 = peg$parsenumber();
                                    if (s2 !== peg$FAILED) {
                                        peg$reportedPos = s0;
                                        s1 = peg$c122(s2);
                                        s0 = s1;
                                    }
                                    else {
                                        peg$currPos = s0;
                                        s0 = peg$c0;
                                    }
                                }
                                else {
                                    peg$currPos = s0;
                                    s0 = peg$c0;
                                }
                            }
                        }
                    }
                }
            }
            return s0;
        }
        function peg$parseconstantExpr() {
            var s0, s1, s2;
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 45) {
                s1 = peg$c42;
                peg$currPos++;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c43);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = peg$parsenumber();
                if (s2 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c122(s2);
                    s0 = s1;
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 43) {
                    s1 = peg$c76;
                    peg$currPos++;
                }
                else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c77);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parsenumber();
                    if (s2 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c117(s2);
                        s0 = s1;
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            return s0;
        }
        function peg$parseviewNameRange() {
            var s0, s1, s2, s3, s4;
            s0 = peg$currPos;
            s1 = peg$currPos;
            s2 = [];
            if (peg$c123.test(input.charAt(peg$currPos))) {
                s3 = input.charAt(peg$currPos);
                peg$currPos++;
            }
            else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c124);
                }
            }
            if (s3 !== peg$FAILED) {
                while (s3 !== peg$FAILED) {
                    s2.push(s3);
                    if (peg$c123.test(input.charAt(peg$currPos))) {
                        s3 = input.charAt(peg$currPos);
                        peg$currPos++;
                    }
                    else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c124);
                        }
                    }
                }
            }
            else {
                s2 = peg$c0;
            }
            if (s2 !== peg$FAILED) {
                s2 = input.substring(s1, peg$currPos);
            }
            s1 = s2;
            if (s1 !== peg$FAILED) {
                s2 = peg$currPos;
                s3 = [];
                if (peg$c125.test(input.charAt(peg$currPos))) {
                    s4 = input.charAt(peg$currPos);
                    peg$currPos++;
                }
                else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c126);
                    }
                }
                while (s4 !== peg$FAILED) {
                    s3.push(s4);
                    if (peg$c125.test(input.charAt(peg$currPos))) {
                        s4 = input.charAt(peg$currPos);
                        peg$currPos++;
                    }
                    else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c126);
                        }
                    }
                }
                if (s3 !== peg$FAILED) {
                    s3 = input.substring(s2, peg$currPos);
                }
                s2 = s3;
                if (s2 !== peg$FAILED) {
                    s3 = peg$parserange();
                    if (s3 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c127(s1, s2, s3);
                        s0 = s1;
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$currPos;
                s2 = [];
                if (peg$c123.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos);
                    peg$currPos++;
                }
                else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c124);
                    }
                }
                if (s3 !== peg$FAILED) {
                    while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        if (peg$c123.test(input.charAt(peg$currPos))) {
                            s3 = input.charAt(peg$currPos);
                            peg$currPos++;
                        }
                        else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c124);
                            }
                        }
                    }
                }
                else {
                    s2 = peg$c0;
                }
                if (s2 !== peg$FAILED) {
                    s2 = input.substring(s1, peg$currPos);
                }
                s1 = s2;
                if (s1 !== peg$FAILED) {
                    s2 = peg$currPos;
                    s3 = [];
                    if (peg$c125.test(input.charAt(peg$currPos))) {
                        s4 = input.charAt(peg$currPos);
                        peg$currPos++;
                    }
                    else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c126);
                        }
                    }
                    while (s4 !== peg$FAILED) {
                        s3.push(s4);
                        if (peg$c125.test(input.charAt(peg$currPos))) {
                            s4 = input.charAt(peg$currPos);
                            peg$currPos++;
                        }
                        else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c126);
                            }
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        s3 = input.substring(s2, peg$currPos);
                    }
                    s2 = s3;
                    if (s2 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c128(s1, s2);
                        s0 = s1;
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            return s0;
        }
        function peg$parseviewName() {
            var s0, s1, s2, s3, s4;
            s0 = peg$currPos;
            s1 = peg$currPos;
            s2 = [];
            if (peg$c123.test(input.charAt(peg$currPos))) {
                s3 = input.charAt(peg$currPos);
                peg$currPos++;
            }
            else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c124);
                }
            }
            if (s3 !== peg$FAILED) {
                while (s3 !== peg$FAILED) {
                    s2.push(s3);
                    if (peg$c123.test(input.charAt(peg$currPos))) {
                        s3 = input.charAt(peg$currPos);
                        peg$currPos++;
                    }
                    else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c124);
                        }
                    }
                }
            }
            else {
                s2 = peg$c0;
            }
            if (s2 !== peg$FAILED) {
                s2 = input.substring(s1, peg$currPos);
            }
            s1 = s2;
            if (s1 !== peg$FAILED) {
                s2 = peg$currPos;
                s3 = [];
                if (peg$c125.test(input.charAt(peg$currPos))) {
                    s4 = input.charAt(peg$currPos);
                    peg$currPos++;
                }
                else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c126);
                    }
                }
                while (s4 !== peg$FAILED) {
                    s3.push(s4);
                    if (peg$c125.test(input.charAt(peg$currPos))) {
                        s4 = input.charAt(peg$currPos);
                        peg$currPos++;
                    }
                    else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c126);
                        }
                    }
                }
                if (s3 !== peg$FAILED) {
                    s3 = input.substring(s2, peg$currPos);
                }
                s2 = s3;
                if (s2 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c128(s1, s2);
                    s0 = s1;
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parserange() {
            var s0, s1, s2, s3;
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c129) {
                s1 = peg$c129;
                peg$currPos += 2;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c130);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = [];
                if (peg$c71.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos);
                    peg$currPos++;
                }
                else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c72);
                    }
                }
                if (s3 !== peg$FAILED) {
                    while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        if (peg$c71.test(input.charAt(peg$currPos))) {
                            s3 = input.charAt(peg$currPos);
                            peg$currPos++;
                        }
                        else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c72);
                            }
                        }
                    }
                }
                else {
                    s2 = peg$c0;
                }
                if (s2 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c131(s2);
                    s0 = s1;
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            return s0;
        }
        function peg$parsenumber() {
            var s0, s1, s2, s3, s4;
            s0 = peg$currPos;
            s1 = [];
            if (peg$c71.test(input.charAt(peg$currPos))) {
                s2 = input.charAt(peg$currPos);
                peg$currPos++;
            }
            else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c72);
                }
            }
            if (s2 !== peg$FAILED) {
                while (s2 !== peg$FAILED) {
                    s1.push(s2);
                    if (peg$c71.test(input.charAt(peg$currPos))) {
                        s2 = input.charAt(peg$currPos);
                        peg$currPos++;
                    }
                    else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c72);
                        }
                    }
                }
            }
            else {
                s1 = peg$c0;
            }
            if (s1 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 46) {
                    s2 = peg$c132;
                    peg$currPos++;
                }
                else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c133);
                    }
                }
                if (s2 !== peg$FAILED) {
                    s3 = [];
                    if (peg$c71.test(input.charAt(peg$currPos))) {
                        s4 = input.charAt(peg$currPos);
                        peg$currPos++;
                    }
                    else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c72);
                        }
                    }
                    if (s4 !== peg$FAILED) {
                        while (s4 !== peg$FAILED) {
                            s3.push(s4);
                            if (peg$c71.test(input.charAt(peg$currPos))) {
                                s4 = input.charAt(peg$currPos);
                                peg$currPos++;
                            }
                            else {
                                s4 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c72);
                                }
                            }
                        }
                    }
                    else {
                        s3 = peg$c0;
                    }
                    if (s3 !== peg$FAILED) {
                        peg$reportedPos = s0;
                        s1 = peg$c134(s1, s3);
                        s0 = s1;
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$c0;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$c0;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$c0;
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = [];
                if (peg$c71.test(input.charAt(peg$currPos))) {
                    s2 = input.charAt(peg$currPos);
                    peg$currPos++;
                }
                else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c72);
                    }
                }
                if (s2 !== peg$FAILED) {
                    while (s2 !== peg$FAILED) {
                        s1.push(s2);
                        if (peg$c71.test(input.charAt(peg$currPos))) {
                            s2 = input.charAt(peg$currPos);
                            peg$currPos++;
                        }
                        else {
                            s2 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c72);
                            }
                        }
                    }
                }
                else {
                    s1 = peg$c0;
                }
                if (s1 !== peg$FAILED) {
                    peg$reportedPos = s0;
                    s1 = peg$c135(s1);
                }
                s0 = s1;
            }
            return s0;
        }
        function extend(dst, ...sources) {
            for (var i = 1; i < arguments.length; i++) {
                for (var k in arguments[i]) {
                    dst[k] = arguments[i][k];
                }
            }
            return dst;
        }
        peg$result = peg$startRuleFunction();
        if (peg$result !== peg$FAILED && peg$currPos === input.length) {
            return peg$result;
        }
        else {
            if (peg$result !== peg$FAILED && peg$currPos < input.length) {
                peg$fail({ type: 'end', description: 'end of input' });
            }
            throw peg$buildException(null, peg$maxFailExpected, peg$maxFailPos);
        }
    }
    return {
        SyntaxError: SyntaxError,
        parse: parse,
    };
})();
