import Attribute from './Attribute.js';
import Relation from './Relation.js';
import Priority from './Priority.js';
import VisualFormat from './VisualFormat.js';
import View from './View.js';
import SubView from './SubView.js';
var AutoLayout = {
    Attribute: Attribute,
    Relation: Relation,
    Priority: Priority,
    VisualFormat: VisualFormat,
    View: View,
    SubView: SubView,
};
export default AutoLayout;
