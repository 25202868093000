import {element} from '@lume/element'
import {Node} from '../core/Node.js'
import {autoDefineElements} from '../LumeConfig.js'

// !! WIP under construction

@element('lume-push-pane-layout', autoDefineElements)
export class PushPaneLayout extends Node {
	constructor() {
		super()
		console.log(' -- PushPaneLayout created (TODO)')
	}

	// TODO
}
