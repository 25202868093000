import {stringAttribute, reactive, booleanAttribute} from '../attribute.js'
import {ClipPlane} from '../../core/ClipPlane.js'
import {MeshBehavior} from './MeshBehavior.js'
import type {MaterialBehavior} from './index.js'

export type ClipPlanesBehaviorAttributes = 'clipPlanes' | 'clipShadows' | 'flipClip' | 'clipDisabled'

let refCount = 0

/**
 * @class ClipPlanesBehavior
 *
 * When applied to an element with GL content, allows specifying one or more
 * [`<lume-clip-plane>`](../../core/ClipPlane) elements to clip the content with.
 *
 * This class extends from `MeshBehavior`, enforcing that the behavior can be used
 * only on elements that have a geometry and material.
 *
 * <div id="clipPlaneExample"></div>
 *
 * <script type="application/javascript">
 *   new Vue({ el: '#clipPlaneExample', data: { code: clipPlaneExample }, template: '<live-code :template="code" mode="html>iframe" :debounce="200" />' })
 * </script>
 *
 * @extends MeshBehavior
 */
@reactive
export class ClipPlanesBehavior extends MeshBehavior {
	/**
	 * @property {boolean} clipShadows
	 *
	 * `attribute`
	 *
	 * Default: `false`
	 *
	 * Defines whether to clip shadows
	 * according to the clipping planes specified on this material. Default is
	 * false.
	 */
	@booleanAttribute(true) clipShadows = true

	// TODO reactive array?
	#clipPlanes: Array<ClipPlane> = []
	#rawClipPlanes: string | Array<ClipPlane | string> = []

	/**
	 * @property {string | Array<ClipPlane | string | null>} clipPlanes
	 *
	 * *attribute*
	 *
	 * Default: `[]`
	 *
	 * The corresponding `clip-planes` attribute accepts one or more selectors,
	 * comma separated, that define which
	 * [`<lume-clip-plane>`](../../core/ClipPlane) elements are to be used as
	 * clip planes. If a selector matches an element that is not a
	 * `<lume-clip-plane>`, it is ignored. If a selector matches more than one
	 * element, all of them that are clip planes are used.
	 *
	 * ```html
	 * <lume-box has="clip-planes" clip-planes=".foo, .bar, #baz"></lume-box>
	 * ```
	 *
	 * The property can also be set with a string (comma separated selectors),
	 * or a mixed array of strings (selectors) or `<lume-clip-plane>` element
	 * instances.
	 *
	 * ```js
	 * el.clipPlanes = ".some-plane"
	 * // or
	 * const plane = document.querySelector('.some-clip-plane')
	 * el.clipPlanes = [plane, "#someOtherPlane"]
	 * ```
	 *
	 * The property getter returns the currently applicable collection of
	 * `<lume-clip-plane>` instances, not the original string or array of values
	 * passed into the attribute or setter. Applicable planes are those that are
	 * connected into the document, and that participate in rendering (composed,
	 * either in the top level document, in a ShadowRoot, or distributed to a
	 * slot in a ShadowRoot).
	 */
	@stringAttribute('')
	get clipPlanes(): Array<ClipPlane> {
		return this.#clipPlanes
	}
	set clipPlanes(value: string | Array<ClipPlane | string>) {
		this.#rawClipPlanes = value

		let array: Array<ClipPlane | string> = []

		if (typeof value === 'string') {
			array = value.split(',').filter(v => !!v.trim())
		} else if (Array.isArray(value)) {
			array = value
		} else {
			throw new TypeError('Invalid value for clipPlanes')
		}

		this.#clipPlanes = []

		for (const v of array) {
			if (typeof v !== 'string') {
				this.#clipPlanes.push(v)
				continue
			}

			let root = this.element.getRootNode() as Document | ShadowRoot | null

			// TODO Should we not search up the composed tree, and stay only
			// in the current ShadowRoot?

			while (root) {
				const plane = root.querySelector(v)

				if (plane) {
					// Find only planes participating in rendering (i.e. in the
					// composed tree, noting that .scene is null when not
					// composed)
					if (plane instanceof ClipPlane && plane.scene) this.#clipPlanes.push(plane)

					// TODO
					// If a lume-clip-plane element was not yet upgraded, it
					// will not be found here. We need to also use
					// MutationObserver on the root, or something, to detect
					// upgraded lume-clip-planes
					//
					// We need to also react to added/removed lume-clip-planes

					// If we found an element, but it's the wrong type, end
					// search, don't use it.
					break
				}

				root = root instanceof ShadowRoot ? (root.host.getRootNode() as Document | ShadowRoot) : null
			}
		}
	}

	/**
	 * @property {boolean} flipClip
	 *
	 * *attribute*
	 *
	 * Default: `false`
	 *
	 * By default, the side of a plane that is clipped is in its positive Z
	 * direction. Setting this to `true` will reverse clipping to the other
	 * side.
	 */
	@booleanAttribute(false) flipClip = false

	/**
	 * @property {boolean} clipDisabled
	 *
	 * *attribute*
	 *
	 * Default: `false`
	 *
	 * If `true`, clipping is not applied.
	 */
	@booleanAttribute(false) clipDisabled = false

	get material() {
		return (this.element.behaviors.find(name => name.endsWith('-material')) as MaterialBehavior).meshComponent
	}

	#observer: MutationObserver | null = null

	override loadGL() {
		if (!refCount) this.element.scene!.__localClipping = true
		refCount++

		// loadGL may fire during parsing before children exist. This
		// MutationObserver will also fire during parsing. This allows us to
		// re-run the query logic for the clip-planes="" prop whenever DOM in
		// the current root changes.
		// TODO we need to observe all the way up the composed tree, or we
		// should make clipPlanes's querying scoped to the nearest root, for
		// consistency.  This covers most cases, for now.
		this.#observer = new MutationObserver(() => {
			// TODO this could be more efficient if we check the added nodes directly, but for now we re-run the query logic.
			// This triggers the setter logic.
			this.clipPlanes = this.#rawClipPlanes
		})

		this.#observer.observe(this.element.getRootNode(), {childList: true, subtree: true})

		this.createEffect(() => {
			const {clipPlanes, clipShadows, flipClip} = this

			// TODO CLIP PLANES REACTIVITY HACK, this is not reactive, so if
			// a material is added later, this won't re-run.
			// MaterialBehavior triggers reactivity, for now, in case this behavior is
			// added to an element.
			// What we need to do is make this.element.behaviors[name].meshComponent reactive
			const mat = this.material
			if (!mat) return

			this.element.needsUpdate()

			if (!clipPlanes.length || this.clipDisabled) {
				mat.clippingPlanes = null
				mat.clipShadows = false // FIXME upstream: don't forget this or Three.js has a bug that still attempts to perform clipping even if clippingPlanes is null.

				return
			}

			if (!mat.clippingPlanes) {
				mat.clippingPlanes = []
			}

			mat.clippingPlanes.length = 0
			mat.clipShadows = clipShadows

			for (const plane of clipPlanes) {
				if (!plane.clip) continue
				mat.clippingPlanes.push(flipClip ? plane.inverseClip : plane.clip)
			}
		})
	}

	override unloadGL() {
		refCount--
		if (!refCount) this.element.scene!.__localClipping = false

		this.#observer?.disconnect()
		this.#observer = null
	}
}

if (!elementBehaviors.has('clip-planes')) elementBehaviors.define('clip-planes', ClipPlanesBehavior)
